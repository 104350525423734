import { useEffect, useState } from 'react';
import { autocompleteClasses, useAutocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { borderColor } from '@mui/system';
import { v4 as uuidv4 } from "uuid";

const Root = styled('div')(
  ({ theme }) => `
    color: ${
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.65)'
        : 'rgba(0,0,0,.85)'
    };
    font-size: 14px;
    font-family: styles.fontFamily;
  `
);

export const InputWrapper = styled('div')(
  ({ theme }) => `
    width: 150px;
    padding: 0px 2px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    border-radius: 4px;
  
    &:hover {
      border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    }
  
    &:focus-within {
      border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  
    & input {
      width: 0;
      min-width: 30px;
      height: 26px;
      margin: 0;
      padding: 4px 6px;
      flex-grow: 1;
      background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
      border: 0;
      box-sizing: border-box;
      color: ${
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,0.65)'
          : 'rgba(0,0,0,.85)'
      };
      outline: 0;
    }
  `
);

const Listbox = styled('ul')(
  ({ theme }) => `
    max-width: 200px;
    min-width: 170px;
    margin: 2px 0 0;
    padding: 0;
    position: absolute;
    list-style: none;
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    overflow: auto;
    max-height: 100px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
  
    & li {
      padding: 5px 12px;
      display: flex;
  
      & span {
        flex-grow: 1;
      }
  
      & svg {
        color: transparent;
      }
    }
  
    & li[aria-selected='true'] {
      background-color: ${
        theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'
      };
      font-weight: 600;
  
      & svg {
        color: #1890ff;
      }
    }
  
    & li.${autocompleteClasses.focused} {
      background-color: ${
        theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'
      };
      cursor: pointer;
  
      & svg {
        color: currentColor;
      }
    }
  `
);

export type AutocompleteSIProps = {
  autocompleteParam: {
    id: string;
    defaultValue?: any;
    value?: any;
    width?:string;
    error?:boolean;
    multiple: boolean;
    options: {}[];
    getOptionLabel: (param: any) => string;
    getOptionDisabled: ((param: any) => any) | undefined;
    isOptionEqualToValue?: (option: any, value: any) => boolean;
  };
  setInputValue: (inputValue: any) => void | null;
};

export default function AutocompleteSI(props: AutocompleteSIProps) {
  const { autocompleteParam, setInputValue } = props;

  const {
    getRootProps,
    // getInputLabelProps,
    getInputProps,
    // getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete(autocompleteParam);

  const [oldValue, setOldValue] = useState<any>(value);

  // Update the state of the parent components when the value is updated
  useEffect(() => {
    // console.log('1', oldValue)
    // console.log('2', value)
    if (typeof value === 'object' && value instanceof Object) {
      if (value != oldValue) {setInputValue(value == null ? undefined : value); setOldValue(value);}
      // else if (value == null) {setInputValue(undefined)}
      // setInputValue(value);
    }
  }, [setInputValue, value]);

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper
          className={focused ? 'focused' : ''} 
          style={{
            width: autocompleteParam.width ? autocompleteParam.width : '150px', 
            borderColor: (autocompleteParam.error == true) ? 'red' : '',
          }}
        >
          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 && (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof autocompleteParam.options).map(
            (option, index) => (
              <li {...getOptionProps({ option, index })}>
                <span>{autocompleteParam.getOptionLabel(option)}</span>
                <FontAwesomeIcon icon={faCheck} />
              </li>
            )
          )}
        </Listbox>
      )}
    </Root>
  );
}
