import { ComingDelivery, Delivery } from '@/app/stores/deliverySlice';
import { hubType } from '@/app/stores/hubSlice';
import { containerType_ogc, productType_ogc } from '@/app/stores/productSlice';
import { capitalizeWords } from '@/features/functions/capitalizeWords';
import { OrderReturn, orderStatusReturn } from '../copApiEndpoints/orderAPI';

const statusAdapter = (status: orderStatusReturn) => {
  let adaptedStatus;
  switch (status) {
    case 'pending':
      return (adaptedStatus = 'Pending');
    case 'scheduled':
    case 'prepared for reposition':
      return (adaptedStatus = 'Scheduled');
    // case 'approved':
    //   return (adaptedStatus = 'Approved');
    case 'in reposition':
      return (adaptedStatus = 'Coming');
    case 'ready':
      return (adaptedStatus = 'Ready');
    // case 'ready to load':
    //   return (adaptedStatus = 'Ready');
    case 'loading':
      return (adaptedStatus = 'Loading');
    case 'loaded':
      return (adaptedStatus = 'Loaded');
    case 'in flight':
      return (adaptedStatus = 'In Flight');
    case 'ready to unload':
      return (adaptedStatus = 'Landed');
    case 'unloading':
      return (adaptedStatus = 'Unloading');
    // TODO: ask BE to fix spelling for status value
    // case 'CANCELED':
    case 'canceled':
    // case 'cancelled':
      return (adaptedStatus = 'Canceled');
    case 'completed':
      return (adaptedStatus = 'Completed');
    case 'aborted':
      return (adaptedStatus = 'Aborted');
    default:
      break;
  }
  return adaptedStatus;
};

export function productsAPI(data: any): productType_ogc[] {
  // This function maps the fetched products data to the products redux state.
  if (typeof data === 'undefined') {
    return [];
  } else {
    return data.map((item: any) => {
      return {
        id: item.id,
        un: item.unNumber || '---',
        name: item.name,
        psn: item.properShippingName || '---',
        primary_class: item.hazards? item.hazards[0] : '---',
        subsidiary_class: item.hazards? (item.hazards[1]? item.hazards.slice(1): '---') : '---',
        packing_group: item.packingGroup || '---',
        hazards: item.hazards,
        // labels: {
        //   prim_label: '',
        //   sec_label: '',
        // },
      };
    });
  }
}

export function ordersAPI(data: OrderReturn[] | undefined): Delivery[] | undefined {
  // This function maps the fetched order data to the delivery redux state.
  // if (typeof data === 'undefined') {
  //   return [];
  // } else {
    return data?.map((item: OrderReturn) => {
      // Define n.a. fields
      let prepared_by, unloaded_by;
      if (item.preparedBy == null) { prepared_by = '---' } else { prepared_by = item.preparedBy.name };
      if (item.unloadedBy == null) { unloaded_by = '---' } else { unloaded_by = item.unloadedBy.name };
      return {
        id: item.id,
        no: item.clientRefs,
        ordered_by: item.orderedBy.name,
        prepared_by: prepared_by,
        unloaded_by: unloaded_by,
        departure: item.startHub.name,
        arrival: item.endHub.name,
        etr: item.timeReadiness,
        eta: item.timeArrival,
        status: statusAdapter(item.status),
        payload: item.payload,
        pdfUrl: item.transportDocumentUrl, 
      };
    });
  // }
}

export function pastOrdersAPI(data: OrderReturn[]): Delivery[] {
  // This function maps the fetched order data to the delivery redux state.
  // if (typeof data === 'undefined') {
  //   return [];
  // } else {
    return data
    .filter((item: OrderReturn) =>
      ['completed', 'canceled', 'aborted'].includes(item.status)
    )
    .map((item: OrderReturn) => {
      // Define n.a. fields
      let prepared_by, unloaded_by;
      if (item.preparedBy == null) {
        prepared_by = '---';
      } else {
        prepared_by = item.preparedBy.name;
      }
      if (item.unloadedBy == null) {
        unloaded_by = '---';
      } else {
        unloaded_by = item.unloadedBy.name;
      }
      return {
        id: item.id,
        no: item.clientRefs,
        ordered_by: item.orderedBy.name,
        prepared_by: prepared_by,
        unloaded_by: unloaded_by,
        departure: item.startHub.name,
        arrival: item.endHub.name,
        etr: item.timeReadiness,
        eta: item.timeArrival,
        status: statusAdapter(item.status),
        payload: item.payload,
        pdfUrl: item.transportDocumentUrl,
      }
    });
  // }
}

export function ordersRtAPI(data: OrderReturn[]): ComingDelivery[] {
  // This function maps the fetched order data to the comingDelivery redux state.
  if (typeof data === 'undefined') {
    return [];
  } else {
    return data.map((item: OrderReturn) => {
      const currentTime = new Date();
      const arrivalTime = new Date(item.timeArrival);
      const diffTime = arrivalTime.getTime() - currentTime.getTime();
      const progress: number = (() => {
        switch (item.status) {
          case 'pending':
          case 'scheduled':
            return 0;
          case 'prepared for reposition':
            return 5;
          case 'in reposition':
            return 10;
          case 'ready':
            return 35;
          case 'loading':
          case 'loaded':
            return 50;
          case 'in flight':
            return 75;
          case 'ready to unload':
            return 95;
          case 'completed':
            return 100;
          case 'canceled':
          case 'aborted':
            return 0;
          default:
            return 0;
        }
      })()
      const remainingTime = item.timeArrival ? diffTime / 60000 : undefined;
      
      return {
        id: item.id,
        no: item.clientRefs,
        departure: item.startHub.name,
        arrival: item.endHub.name,
        remaining_time: remainingTime?.toString() ?? undefined, // tbd
        progress: progress, // tbd
        status: statusAdapter(item.status),
      };
    });
  }
}

export function hubsAPI(data: any): hubType[] {
  // This function maps the fetched hub data to the hub redux state.
  if (typeof data === 'undefined') {
    return [];
  } else {
    return data.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        picture: item.img_url || 'sgs_logo.png',
        location: item.location,
        lng: item.lng,
        lat: item.lat,
        companyId: item.companyId,
      };
    });
  }
}

export function containersAPI(data: any): containerType_ogc[] {
  // This function maps the fetched products data to the products redux state.
  if (typeof data === 'undefined') {
    return [];
  } else {
    return data.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  }
}


